import React from 'react'
import './style.scss'

import { ReactComponent as Next } from '../../gfx/button-next.svg'
import { ReactComponent as Prev } from '../../gfx/button-prev.svg'

const PrevNextNav = ({
  nextHandler,
  prevHandler,
  nextDisabled,
  prevDisabled,
  sober,
  vertical,
}) => {
  if (nextDisabled && prevDisabled) return ''
  else
    return (
      <div
        className={`c-prev-next-button-wrapper c-prev-next-button-wrapper--${vertical &&
          'vertical'}`}
      >
        <button
          disabled={prevDisabled}
          className={`c-prev-next-button c-prev-next-button--next ${
            sober ? 'c-prev-next-button--sober' : ''
          }`}
          onClick={prevHandler}
        >
          <Prev />
        </button>
        <button
          disabled={nextDisabled}
          className={`c-prev-next-button c-prev-next-button--prev  ${
            sober ? 'c-prev-next-button--sober' : ''
          }`}
          onClick={nextHandler}
        >
          <Next />
        </button>
      </div>
    )
}

export default PrevNextNav
