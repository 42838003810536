import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import HeroSingle from '../components/HeroSingle'
import ContactCTA from '../components/ContactCTA'
import CTABlock from '../components/CTABlock'
import History from '../components/History'
import Layout from '../components/Layout'

import './style.scss'
import SEO from '../components/SEO/index'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  render() {
    const { data, location } = this.props
    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="position-relative">
          <HeroSingle data={data.page} />
          <CTABlock compact data={data.page.frontmatter.cta_block} />
        </div>
        <History data={data.page.frontmatter.historyItems} />
        <ContactCTA />
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const historyQuery = graphql`
  query historyQuery {
    page: markdownRemark(fields: { slug: { eq: "/history/" } }) {
      id
      ...SEO
      ...ctaBanner
      ...ctaBlock
      frontmatter {
        title
        introTitle: intro_title
        historyItems: history_items {
          node: childMarkdownRemark {
            id
            html
            frontmatter {
              year
              employees
              employeesIcon: employees_icons {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
