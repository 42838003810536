import React, { Component } from 'react'
import scrollToComponent from 'react-scroll-to-component'

// library to make the navigation bar sticky
import Sticky from 'react-sticky-el'

import InViewMonitor from 'react-inview-monitor'
import Scrollspy from 'react-scrollspy'

import './style.scss'
import PrevNextNav from '../PrevNextNav'
import MassiveTitle from '../MassiveTitle/index'

export default class History extends Component {
  constructor(props) {
    super(props)
    this.scrollTo = this.scrollTo.bind(this)
    this.nextItem = this.nextItem.bind(this)
    this.prevItem = this.prevItem.bind(this)
    this.getActiveIndex = this.getActiveIndex.bind(this)
    this.maxIndex = props.data.length - 1
    this.sectionIds = this.props.data.map(({ node }) => node.id)
  }

  state = {
    activeItem: this.props.data[0].node.id,
  }

  scrollTo(item) {
    scrollToComponent(item, {
      offset: -80,
      align: 'top',
      duration: 500,
    })
  }

  getActiveIndex() {
    return this.sectionIds.indexOf(this.state.activeItem)
  }

  nextItem() {
    // check the index of the current active item
    let nextIndex = this.getActiveIndex() + 1
    if (nextIndex >= this.maxIndex) nextIndex = this.maxIndex

    this.scrollTo(this[this.sectionIds[nextIndex]])
  }

  prevItem() {
    let prevIndex = this.getActiveIndex() - 1
    if (prevIndex < 0) prevIndex = 0
    this.scrollTo(this[this.sectionIds[prevIndex]])
  }

  render() {
    const { data } = this.props
    return (
      <div className="c-history-wrapper position-relative">
        <MassiveTitle modifiers={['left']}>History</MassiveTitle>
        <div className="container position-relative c-history-container">
          <Sticky
            className="c-sticky-bit"
            boundaryElement=".c-history-container"
            style={{ position: 'relative', zIndex: 10 }}
            hideOnBoundaryHit={false}
            bottomOffset={80}
            hideOnBoundaryHit={false}
          >
            <nav className=" c-history-nav d-none d-sm-flex">
              <Scrollspy
                className="c-history-nav__inner"
                componentTag="div"
                offset={-100}
                items={data.map(item => item.node.id)}
                currentClassName="is-active"
                onUpdate={target => {
                  if (target) {
                    this.setState({ activeItem: target.id })
                  }
                }}
              >
                {data.map(item => {
                  return (
                    <a
                      href={'#' + item.node.id}
                      key={item.node.id}
                      onClick={e => {
                        e.preventDefault()
                        this.scrollTo(this[item.node.id])
                      }}
                      className={`c-history-nav__button`}
                    >
                      {item.node.frontmatter.year}
                    </a>
                  )
                })}
              </Scrollspy>
              <PrevNextNav
                vertical
                nextHandler={this.nextItem}
                prevHandler={this.prevItem}
              />
            </nav>
          </Sticky>
          {data.map((item, index) => (
            <InViewMonitor
              classNameInView="is-active"
              toggleClassNameOnInView={true}
              key={item.node.id}
            >
              <article
                id={item.node.id}
                className={`row pr-sm-5 c-history`}
                ref={section => (this[item.node.id] = section)}
              >
                <div
                  className={`col-sm-7 bg-light p-2 p-sm-5 mb-3 ${index % 2 !=
                    0 && 'offset-sm-5'} position-relative`}
                >
                  <div
                    className={`c-history__year ${index % 2 != 0 &&
                      'c-history__year--left'} text-primary`}
                  >
                    {item.node.frontmatter.year}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.node.html }} />
                  <div>
                    <span>
                      <img
                        className="c-history__team-icon"
                        src={
                          item.node.frontmatter.employeesIcon.childImageSharp
                            .original.src
                        }
                        alt={item.node.frontmatter.employees}
                      />
                    </span>
                    <span className="c-history__team-description text-primary ml-2">
                      <span className="text-mid">
                        {item.node.frontmatter.employees}
                      </span>
                    </span>
                  </div>
                </div>
              </article>
            </InViewMonitor>
          ))}
        </div>
      </div>
    )
  }
}
